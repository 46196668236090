<template>
  <div class="club-plan-item">
    <span class="col1 sub-text-s2">
      {{ plan.title }}
    </span>
    <span class="col2 sub-text-s2">{{ state.salesPrice }}</span>
    <div class="col3">
      <span class="status-btn sub-text-s2">
        {{ statusLabel }}
      </span>
    </div>
    <div class="col4">
      <div v-if="state.showPriceInfo" class="origin-price">
        <span class="sub-text-s3 text-gray-second">
          {{ state.listPrice }}
        </span>
        <span class="sub-text-s3 text-red-50">
          {{ state.discount }}
        </span>
      </div>
      <span class="sub-text-s2">
        {{ state.price }}
      </span>
    </div>
    <div class="col5">
      <span
        class="sub-text-s3"
        :class="state.soldOut ? 'text-red-50' : 'text-blue-50'"
      >
        {{ plan.paymentOrderCount }}명 구매
      </span>
      <br />
      <span class="sub-text-s3">
        {{ state.limitCount }}
      </span>
    </div>
    <div class="col6 sub-text-s3">
      {{ state.remainTickets }}
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import moment from "moment-timezone";
import Cookies from "js-cookie";
import helper from "@/helper";
import {
  planDiscount,
  planPrice,
  planSalesPrice,
  planSoldOut,
  planListPrice,
} from "@/helper/plan";
import { displayStatusLabel } from "@/helper/club";

export default {
  name: "ClubPlanItem",
  components: {},
  props: {
    plan: {
      type: Object,
      required: true,
    },
    clubResourceId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      salesPrice: computed(() => {
        return planSalesPrice(props.plan, "front");
      }),
      currency: Cookies.get("locale") === "ko" ? "₩" : "$",
      priceObject: computed(() => {
        if (props.plan.prices?.KRW) {
          return props.plan.prices.KRW;
        }
        if (props.plan.prices?.USD) {
          return props.plan.prices.USD;
        }
        return {
          currency: "KRW",
          listPrice: 0,
          listPriceGoods: 0,
          listPriceTicket: 0,
          price: 0,
          priceGoods: 0,
          priceTicket: 0,
        };
      }),
      limitCount: computed(() => {
        return props.plan.limitCount
          ? `${helper.priceFormat(props.plan.limitCount)}명 한정`
          : "제한 없음";
      }),
      remainTickets: computed(() => {
        if (props.plan.limitCount > 0) {
          return `${helper.priceFormat(
            props.plan.limitCount - props.plan.paymentOrderCount
          )} 개`;
        } else {
          return "제한 없음";
        }
      }),
      showPriceInfo: computed(() => {
        return (
          !!state.priceObject.listPrice &&
          state.priceObject.listPrice > state.priceObject.price
        );
      }),
      listPrice: computed(() => {
        return planListPrice(props.plan);
      }),
      price: computed(() => {
        return planPrice(props.plan, "front");
      }),
      soldOut: computed(() => {
        return planSoldOut(props.plan);
      }),
      discount: computed(() => {
        return planDiscount(props.plan);
      }),
    });

    const statusLabel = computed(() => {
      const status = props.plan.status;
      return displayStatusLabel(status);
    });

    return { state, helper, statusLabel };
  },
};
</script>

<style src="./style.css" scoped></style>
