<template>
  <div class="input-radio-groups" :class="{ 'inactive-mode': isInactive }">
    <span v-for="(item, index) in items" :key="index">
      <input
        :id="item.label"
        class="visually-hidden"
        :name="name"
        type="radio"
        :value="item.value"
        :checked="item.value == state.checkedItem"
        :disabled="isInactive"
        @change="actions.updateData($event, item.label)"
      />
      <label :for="item.label" :class="state.textSize">{{ item.label }} </label>
    </span>
  </div>
</template>

<script>
import { computed, onMounted, reactive } from "vue";

export default {
  name: "InputRadioGroups",
  props: {
    name: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    label: {
      type: String,
      required: true,
    },
    textSize: {
      type: String,
      default: "s1",
      validator(value) {
        return ["s1", "s2", "s3"].includes(value);
      },
    },
    initialValue: {
      type: [String, Number],
      required: true,
    },
    isInactive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateValue"],
  setup(props, { emit }) {
    const state = reactive({
      checkedItem: "",
      textSize: computed(() => {
        return `sub-title-${props.textSize}`;
      }),
    });

    onMounted(() => {
      state.checkedItem = props.initialValue;
    });

    const actions = {
      updateData: (e, label) => {
        if (!props.isInactive) {
          // todo 값 타입 체크
          emit("update-value", { value: e.target.value, label });
          state.checkedItem = e.target.value;
        }
      },
    };

    return { state, actions };
  },
};
</script>

<style scoped>
.visually-hidden {
  display: none;
}

.input-radio-groups {
  border: 1px solid #8e1eff;
  border-radius: 24px;
  width: fit-content;
  overflow: hidden;
}

.input-radio-groups span {
  display: inline-block;
}

.input-radio-groups span:hover label {
  color: #ffffff;
  background-color: #8e1eff;
  cursor: pointer;
}

.input-radio-groups span label {
  display: block;
  color: #8e1eff;
  padding: 11px 16px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

.input-radio-groups span input[type="radio"]:checked + label {
  color: #ffffff;
  background-color: #8e1eff;
}

.input-radio-groups.inactive-mode span:hover label {
  color: #8e1eff;
  background-color: unset;
  cursor: default;
}
</style>
