export function displayStatusLabel(status) {
  switch (status) {
    case 1:
      return "판매 예정";
    case 4:
      return "판매 종료";
    case 8:
      return "숨김";
    default:
      return "판매중";
  }
}
