<template>
  <div>
    <div class="plan-title-section">
      <text-divider
        width="2px"
        height="36px"
        color="#0D0D10"
        margin="0 12px 0 0"
      ></text-divider>
      <h5>{{ plan.title }}</h5>
    </div>
    <ul class="sales-info">
      <li>
        <span class="sub-text-s3 text-gray-second">티켓별 매출</span>
        <span class="sub-text-s3"> {{ state.salesPrice }} </span>
      </li>
      <li>
        <span class="sub-text-s3 text-gray-second">티켓 상태</span>
        <span class="sub-text-s3">{{ statusLabel }}</span>
      </li>
      <li>
        <span class="sub-text-s3 text-gray-second"> 티켓 가격 </span>
        <span class="sub-text-s3">
          {{ state.price }}
        </span>
      </li>
      <li>
        <span class="sub-text-s3 text-gray-second"> 판매 수량 </span>
        <span class="sales-count">
          <span
            class="sub-text-s3"
            :class="`${
              plan.limitCount > 0 && plan.limitCount === plan.paymentOrderCount
                ? 'text-red-50'
                : 'text-blue-50'
            }`"
          >
            {{ plan.paymentOrderCount }} 명 구매
          </span>
          <br />
          <span class="sub-text-s3">
            {{ state.limitCount }}
          </span>
        </span>
      </li>
      <li>
        <span class="sub-text-s3 text-gray-second"> 남은 수량 </span>
        <span class="sub-text-s3"> {{ state.remainTickets }} </span>
      </li>
    </ul>
  </div>
</template>

<script>
import helper from "@/helper";
import { computed, reactive } from "vue";
import TextDivider from "@/components/console/dividers/TextDivider";
import { planPrice, planSalesPrice } from "@/helper/plan";
import { displayStatusLabel } from "@/helper/club";

export default {
  name: "ClubPlanItemMobile",
  components: { TextDivider },
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      salesPrice: computed(() => {
        return planSalesPrice(props.plan, "front");
      }),
      price: computed(() => {
        return planPrice(props.plan, "front");
      }),
      remainCount: computed(() => {
        if (props.plan.limitCount === 0) {
          return "-";
        } else {
          return `${helper.priceFormat(
            props.plan.limitCount - props.plan.salesCount
          )}개`;
        }
      }),
      limitCount: computed(() => {
        return props.plan.limitCount
          ? `${helper.priceFormat(props.plan.limitCount)}명 한정`
          : "제한 없음";
      }),
      remainTickets: computed(() => {
        if (props.plan.limitCount > 0) {
          return `${helper.priceFormat(
            props.plan.limitCount - props.plan.paymentOrderCount
          )} 개`;
        } else {
          return "제한 없음";
        }
      }),
    });

    const statusLabel = computed(() => {
      const status = props.plan.status;
      return displayStatusLabel(status);
    });

    return { state, helper, statusLabel };
  },
};
</script>

<style src="./style.css" scoped></style>
