<template>
  <div class="club-card">
    <div class="club-info">
      <div class="featured-img-wrapper">
        <div class="featured-img" :style="state.clubFeaturedSectionStyle"></div>
      </div>

      <div class="text-info">
        <div class="title-wrapper">
          <h4 class="title">
            {{ club.title }}
            <export-icon
              class="mo-icon"
              fill-color="#0D0D10"
              @click="actions.goToClubPage()"
            ></export-icon>

            <export-icon
              class="pc-icon"
              fill-color="#0D0D10"
              width="18px"
              height="18px"
              view-box="2 2 14 14"
              @click="actions.goToClubPage()"
            ></export-icon>
          </h4>
        </div>

        <div class="status">
          <p v-if="state.publishedAt" class="text-gray-second">
            {{ state.publishedAt }} ~
          </p>
          <p class="text-gray-second">{{ statusLabel }}</p>
        </div>
      </div>
    </div>

    <div class="meta-list-wrapper">
      <ul>
        <li>
          <div class="item">
            <div class="item-title">
              <wallet-icon
                width="16"
                height="16"
                view-box="0 0 16 16"
              ></wallet-icon>

              <span class="txt sub-text-s3 text-gray-second">
                전체 판매 금액
              </span>
            </div>
            <div class="item-value">
              <span class="value">{{ state.salesPrice }}</span>
              <span class="unit text-gray-second">원</span>
            </div>
          </div>
        </li>
        <li>
          <div class="item">
            <div class="item-title">
              <ticket-icon :fill="true" fill-color="#818287"></ticket-icon>

              <span class="txt sub-text-s3 text-gray-second">
                전체 구매자 수
              </span>
            </div>
            <div class="item-value">
              <span class="value sub-title-s3">{{ state.salesCount }}</span>
              <span class="sub-text-s3 text-gray-second">명</span>
            </div>
          </div>
        </li>
        <li>
          <div class="item">
            <div class="item-title">
              <smile-icon width="16" height="16" :fill="true"></smile-icon>

              <span class="txt sub-text-s3 text-gray-second">
                페이지 조회 수
              </span>
            </div>
            <div class="item-value">
              <span class="value sub-title-s3">
                {{ state.viewCount }}
              </span>
              <span class="sub-text-s3 text-gray-second">회</span>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="button-wrapper">
      <button v-if="false" class="sub-title-s2" @click="actions.goToClubEdit()">
        수정
      </button>
      <button v-if="false" class="sub-title-s2" @click="actions.shareLink">
        공유
      </button>
    </div>

    <teleport :disabled="true">
      <warning-modal
        v-if="state.showGoToPcModal"
        warning-title="수정은 PC에서만 가능해요"
        warning-text="수정을 원하신다면 PC 접속 부탁드립니다."
        confirm-text="확인"
        :show-cancel-button="false"
        @confirm="actions.closeGoToPcModal()"
      ></warning-modal>
    </teleport>
  </div>
</template>

<script>
import { computed, getCurrentInstance, reactive } from "vue";
import moment from "moment-timezone";
import Cookies from "js-cookie";
import { useRouter } from "vue-router";
import helper from "@/helper";
import { useStore } from "vuex";
import ExportIcon from "../../../../components/console/icons/ExportIcon";
import WalletIcon from "../../../../components/console/icons/WalletIcon";
import TicketIcon from "../../../../components/console/icons/TicketIcon";
import SmileIcon from "../../../../components/console/icons/SmileIcon";
import swal from "@/helper/swal";
import WarningModal from "../../../../components/console/modals/WarningModal";
import { displayStatusLabel } from "@/helper/club";

export default {
  name: "ClubCard",
  components: { WarningModal, SmileIcon, TicketIcon, WalletIcon, ExportIcon },
  props: {
    club: {
      type: Object,
      require: true,
    },
  },
  emits: ["editClub"],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const blankImage = proxy.$const.blankImage;
    const router = useRouter();
    const store = useStore();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      clubFeaturedSectionStyle: computed(() => {
        return `background-image: url(${props.club.featuredImage}), url(${blankImage})`;
      }),
      publishedAt: computed(() => {
        if (props.club.publishedAt) {
          return moment(props.club.publishedAt)
            .tz(moment.tz.guess())
            .format("YYYY.MM.DD hh:mm z");
        }
        return null;
      }),
      currency: computed(() => {
        return Cookies.get("locale") === "ko" ? "₩" : "$";
      }),
      salesPrice: computed(() => {
        return props.club.salesPrice === 0
          ? "-"
          : helper.priceFormat(props.club.salesPrice);
      }),
      salesCount: computed(() => {
        return props.club.salesCount === 0
          ? "-"
          : helper.priceFormat(props.club.salesCount);
      }),
      viewCount: computed(() => {
        return props.club.viewCount === 0
          ? "-"
          : helper.priceFormat(props.club.viewCount);
      }),
      showGoToPcModal: false,
    });

    const statusLabel = computed(() => {
      const status = props.club.status;
      return displayStatusLabel(status);
    });

    const actions = {
      goToClubEdit: () => {
        if (!helper.isTabletSize()) {
          emit("editClub");
        } else {
          state.showGoToPcModal = true;
        }
      },
      goToClubPage: () => {
        let clubUrl = helper.getEnterClubWebUrl(state.user, props.club);
        window.open(clubUrl, "_blank");
      },
      shareLink: async () => {
        let sendUrl = await helper.clubShareUrl(state.user, props.club);

        helper.copyText(sendUrl);
        await swal.createCompleteToast("주소가 복사되었습니다.");
      },
      closeGoToPcModal: () => {
        state.showGoToPcModal = false;
      },
    };

    return { state, actions, statusLabel };
  },
};
</script>

<style src="./style.css" scoped></style>
